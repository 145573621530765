import {
  ColleagueDTO,
  CvDTO,
  CvFileType,
  CvListItemDTO,
  GenerateCvsParams,
  SaveNewCVDTO,
  UserTechnologyDTO,
} from '@bb-sanctuary/common';
import { AxiosResponse } from 'axios';
import { axiosInstance } from './axiosInstance';

const url = {
  cvGeneratorApi: `/api/cv-generator`,
};

export const CvGeneratorService = {
  async getCvData(email: string): Promise<CvDTO> {
    return (await axiosInstance.get(`${url.cvGeneratorApi}/content/${email}`)).data;
  },
  async getUserTechList(): Promise<UserTechnologyDTO[]> {
    return (await axiosInstance.get(`${url.cvGeneratorApi}/technologies`)).data;
  },
  async downloadCvByEmail(email: string) {
    return (await axiosInstance.get(`${url.cvGeneratorApi}/generate-by-email/${email}`)).data;
  },
  async downloadCvById(id: number) {
    return (await axiosInstance.get(`${url.cvGeneratorApi}/generate-by-id/${id}`)).data;
  },
  async generateZip(data: GenerateCvsParams,
                    fileType: CvFileType) {
    return (await axiosInstance.post(`${url.cvGeneratorApi}/generate/zip${fileType === CvFileType.pdf ? `?isPdf=true` : ''}`, data)).data;
  },
  async saveCvData(cv: SaveNewCVDTO) {
    return (await axiosInstance.post(`${url.cvGeneratorApi}`, cv)).data;
  },
  async getCvList(email: string): Promise<CvListItemDTO[]> {
    return (await axiosInstance.get(`${url.cvGeneratorApi}/cv-list/${email}`)).data;
  },
  async getCvUser(): Promise<AxiosResponse<ColleagueDTO[]>> {
    return (await axiosInstance.get(`${url.cvGeneratorApi}/users`));
  },
  async downloadCsv(): Promise<AxiosResponse<string>> {
    return (await axiosInstance.get(`${url.cvGeneratorApi}/mass-download`));
  },
};
