import {
  CvCompetenceDTO,
  CvCourseDTO,
  CvEducationDTO,
  CvLanguageDTO,
  CvProfessionalBackgroundDTO,
  CvProjectDTO,
  DevelopmentMethodology,
  EducationLevel,
  SaveNewCVDTO,
} from '@bb-sanctuary/common';
import { Alert, AlertColor, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../contexts/currentUserSlicer';
import BsButton from '../shared/components/ui/button/button';
import { CvGeneratorService } from '../shared/service/cvGeneratorService';
import ConfirmationModal from '../shared/components/custom/cv-form/ConfirmationModal';
import Translation from '../shared/data/translation';
import { NewLanguage } from '../shared/components/custom/cv-form/CvLanguageForm';
import { Link } from 'react-router-dom';
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping';
import { BsContentHeader } from '../shared/components/layout/header/bsContentHeader';
import { CvGeneratorHeader } from '../shared/components/custom/cv-generator-header/CvGeneratorHeader';
import { CvGeneratorAccordionSummary } from '../shared/components/custom/cv-generator-accordion-summary/CvGeneratorAccordionSummary';
import { CvGeneratorAccordionProfessionalBackground } from '../shared/components/custom/cv-generator-accordion-professional-background/CvGeneratorAccordionProfessionalBackground';
import {
  CvGeneratorAccordionDomain,
  DomainKnowledge,
} from '../shared/components/custom/cv-generator-accordion-domain/CvGeneratorAccordionDomain';
import { CvGeneratorAccordionLanguage } from '../shared/components/custom/cv-generator-accordion-language/CvGeneratorAccordionLanguage';
import { CvGeneratorAccordionDevelopmentMtd } from '../shared/components/custom/cv-generator-accordion-development-mtd/CvGeneratorAccordionDevelopmentMtd';
import { CvGeneratorAccordionEducation } from '../shared/components/custom/cv-generator-accordion-education/CvGeneratorAccordionEducation';
import { CvGeneratorAccordionCompetence } from '../shared/components/custom/cv-generator-accordion-competence/CvGeneratorAccordionCompetence';
import { CvGeneratorAccordionCourse } from '../shared/components/custom/cv-generator-accordion-course/CvGeneratorAccordionCourse';
import { CvGeneratorAccordionProject } from '../shared/components/custom/cv-generator-accordion-project/CvGeneratorAccordionProject';

export enum SnackbarStatus {
  error = 'error',
  success = 'success',
  warning = 'warning',
  default = '',
}

export interface SnackbarIsOpen {
  isOpen: boolean;
  message: string;
  status: SnackbarStatus;
}

const maxCvItems = {
  shortSummary: 500,
  professionalBackground: 8,
  domainKnowledge: 8,
  competence: 10,
  course: 8,
};

const requiredCvItems = {
  shortSummary: 100,
  professionalBackground: 1,
  competence: 1,
  language: 1,
  education: 1,
  projects: 1,
};

const CvGeneratorPage = () => {
  const currentUser = useSelector(selectCurrentUser);
  const [shortSummary, setShortSummary] = useState<string>('');
  const [domainKd, setDomainKd] = useState<DomainKnowledge[]>([]);
  const [languages, setLanguages] = useState<CvLanguageDTO[]>([]);
  const [developmentMtd, setDevelopmentMtd] = useState<DevelopmentMethodology[]>([]);
  const [educations, setEducations] = useState<CvEducationDTO[]>([]);
  const [competencies, setCompetencies] = useState<CvCompetenceDTO[]>([]);
  const [courses, setCourses] = useState<CvCourseDTO[]>([]);
  const [projects, setProjects] = useState<CvProjectDTO[]>([]);
  const [professionalBackgrounds, setProfessionalBackgrounds] = useState<CvProfessionalBackgroundDTO[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [snackBarIsOpen, setSnackBarIsOpen] = useState<SnackbarIsOpen>({
    isOpen: false,
    message: '',
    status: SnackbarStatus.default,
  });
  const [editedBg, setEditedBg] = useState<CvProfessionalBackgroundDTO>();
  const [editedProj, setEditedProj] = useState<CvProjectDTO>();
  const [editedEdu, setEditedEdu] = useState<CvEducationDTO>();
  const [editedCourse, setEditedCourse] = useState<CvCourseDTO>();
  const [hasChanges, setHasChanges] = useState(false);

  const onUpdateDomainKnowledge = (domainList: DomainKnowledge[]) => {
    setDomainKd(domainList);
    setHasChanges(true);
  };

  const onAddNewlanguage = (newLanguage: NewLanguage) => {
    const languageIsAlreadyExist = languages.some(
      (language) => language.language.toLowerCase() === newLanguage.title.toLowerCase(),
    );
    if (languageIsAlreadyExist) {
      setSnackBarIsOpen({ isOpen: true, message: 'Language is already added.', status: SnackbarStatus.warning });
      return false;
    } else {
      setLanguages([
        {
          id: Math.floor(Math.random() * 1000000),
          language: newLanguage.title,
          level: newLanguage.level,
        },
        ...languages,
      ]);
      setHasChanges(true);
      return true;
    }
  };

  const onRemoveLanguage = (id: number) => {
    const newLanguagesArray = languages.filter((item) => item.id !== id);
    setLanguages(newLanguagesArray);
    setHasChanges(true);
  };

  const updateSummary = (val: string) => {
    setShortSummary(val);
    setHasChanges(true);
  };

  const updateDevelopmentMtd = (val: DevelopmentMethodology[]) => {
    setDevelopmentMtd(val);
    setHasChanges(true);
  };

  const onAddNewEducation = (newEducation?: CvEducationDTO) => {
    if (!newEducation) {
      setEditedEdu(undefined);
      return true;
    }

    const newEducations = [
      ...(editedEdu ? educations.filter((edu) => edu.id !== editedEdu.id) : educations),
      newEducation,
    ];

    const duplicate = newEducations.some(
      (edu) =>
        edu.title.toLowerCase() === newEducation.title.toLowerCase() &&
        edu.faculty.toLowerCase() === newEducation.faculty.toLowerCase() &&
        edu.level.toLowerCase() === newEducation.level.toLowerCase() &&
        edu.id !== newEducation.id,
    );

    if (duplicate) {
      setSnackBarIsOpen({
        isOpen: true,
        message: Translation.hu.cv.form.eduDuplicateError,
        status: SnackbarStatus.warning,
      });
      return false;
    }

    const bscList = newEducations.filter((_) => _.level === EducationLevel.BSc);
    const mscList = newEducations.filter((_) => _.level === EducationLevel.MSc);

    const hasOverlappedEdu = mscList.some((mscEdu) =>
      bscList.some(
        (bscEdu) =>
          mscEdu.title.toLowerCase() === bscEdu.title.toLowerCase() &&
          mscEdu.faculty.toLowerCase() === bscEdu.faculty.toLowerCase() &&
          areIntervalsOverlapping(
            { start: new Date(bscEdu.startDate), end: bscEdu.endDate ? new Date(bscEdu.endDate) : new Date() },
            { start: new Date(mscEdu.startDate), end: mscEdu.endDate ? new Date(mscEdu.endDate) : new Date() },
          ),
      ),
    );

    if (hasOverlappedEdu) {
      setSnackBarIsOpen({
        isOpen: true,
        message: Translation.hu.cv.form.eduOverlapError,
        status: SnackbarStatus.warning,
      });
      return false;
    }

    const sortedEducation = newEducations.sort(
      (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
    );

    setEducations(sortedEducation);
    setHasChanges(true);
    return true;
  };

  const onRemoveEducation = (id: number) => {
    const newEducationsArray = educations.filter((item) => item.id !== id);
    setEducations(newEducationsArray);
    setHasChanges(true);
  };

  const onEditEducation = (id: number) => {
    setEditedEdu(educations.find((item) => item.id === id));
  };

  const onEditCourse = (id: number) => {
    setEditedCourse(courses.find((item) => item.id === id));
  };

  const onAddNewCompetence = (newCompetence: CvCompetenceDTO[]) => {
    const newCompetencies = newCompetence.filter(
      (newCompetence) => !competencies.find((comp) => comp.description === newCompetence.description),
    );
    if (competencies.length + newCompetencies.length > 10) {
      setSnackBarIsOpen({
        isOpen: true,
        message: 'Maximum 10 competencies can be added',
        status: SnackbarStatus.warning,
      });
    }

    const newList = [...competencies, ...newCompetencies];
    newList.splice(10);
    setCompetencies(newList);
    setHasChanges(true);
  };

  const onRemoveCompetence = (id: number) => {
    const newCompetenceArray = competencies.filter((item) => item.id !== id);
    setCompetencies(newCompetenceArray);
    setHasChanges(true);
  };

  const onUpdateCompetence = (updatedCompetence: CvCompetenceDTO) => {
    const editedCompetenceInd = competencies.findIndex((comp) => comp.id === updatedCompetence.id);
    if (editedCompetenceInd > -1) {
      competencies.splice(editedCompetenceInd, 1, updatedCompetence);
      setCompetencies(competencies);
      setHasChanges(true);
    }
  };

  const onAddNewCourse = (newCourse?: CvCourseDTO) => {
    if (!newCourse) {
      setEditedCourse(undefined);
      return;
    }
    if (!editedCourse) {
      setCourses([...courses, newCourse]);
    } else {
      const updateIndex = courses.findIndex((bg) => bg.id === editedCourse.id);
      if (updateIndex > -1) {
        courses[updateIndex] = newCourse;
        setEditedCourse(undefined);
        setCourses([...courses]);
      }
    }
    setHasChanges(true);
  };

  const onRemoveCourse = (id: number) => {
    const newCourseArray = courses.filter((item) => item.id !== id);
    setCourses(newCourseArray);
    setHasChanges(true);
  };

  const onAddNewProject = (newProject?: CvProjectDTO) => {
    if (!newProject) {
      setEditedProj(undefined);
      return;
    }
    if (!editedProj) {
      const sortedProject = [newProject, ...projects].sort(
        (a, b) => new Date(a.fromDate).getTime() - new Date(b.fromDate).getTime(),
      );
      setProjects(sortedProject);
    } else {
      const updateIndex = projects.findIndex((bg) => bg.id === editedProj.id);
      if (updateIndex > -1) {
        projects[updateIndex] = newProject;
        setEditedProj(undefined);
        setProjects([...projects]);
      }
    }
    setHasChanges(true);
  };

  const onRemoveProject = (id: number) => {
    const newProjectArray = projects.filter((item) => item.id !== id);
    setProjects(newProjectArray);
    setHasChanges(true);
  };

  const onEditProject = (id: number) => {
    setEditedProj(projects.find((item) => item.id === id));
  };

  const onAddNewProfessionalBg = (newProfessionalBg: CvProfessionalBackgroundDTO | null) => {
    if (!newProfessionalBg) {
      setEditedBg(undefined);
      return;
    }
    if (!editedBg) {
      const sortedProfessionalBackgrounds = [newProfessionalBg, ...professionalBackgrounds].sort(
        (a, b) => new Date(a.fromDate).getTime() - new Date(b.fromDate).getTime(),
      );
      setProfessionalBackgrounds(sortedProfessionalBackgrounds);
    } else {
      const updateIndex = professionalBackgrounds.findIndex((bg) => bg.id === editedBg.id);
      if (updateIndex > -1) {
        professionalBackgrounds[updateIndex] = newProfessionalBg;
        setEditedBg(undefined);
        setProfessionalBackgrounds([...professionalBackgrounds]);
      }
    }
    setHasChanges(true);
  };

  const onRemoveProfessionalBg = (id: number) => {
    const newProfessionalBgArray = professionalBackgrounds.filter((item) => item.id !== id);
    setProfessionalBackgrounds(newProfessionalBgArray);
    setHasChanges(true);
  };

  const onEditProfessionalBg = (id: number) => {
    const editElement = professionalBackgrounds.find((item) => item.id === id);

    setEditedBg({
      ...editElement!,
      description: [...(editElement!.description || []), ''],
    });
  };

  const handleOnClose = (confirm: boolean) => {
    if (confirm) {
      saveCv();
    }

    setModalIsOpen(false);
  };

  const handleSnackBarClose = () => {
    setSnackBarIsOpen({ isOpen: false, message: '', status: SnackbarStatus.default });
  };

  const saveCv = async () => {
    const cv: SaveNewCVDTO = {
      backgrounds: professionalBackgrounds,
      competencies,
      courses,
      developmentMethodologies: developmentMtd,
      domainKnowledges: domainKd.map((i) => i.title),
      educations,
      languages: languages.map((language) => {
        return {
          id: language.id,
          language: language.language,
          level: language.level,
          order: 0,
        };
      }),
      projects,
      shortSummary,
    };

    try {
      await CvGeneratorService.saveCvData(cv);
      setHasChanges(false);
      setSnackBarIsOpen({
        isOpen: true,
        message: Translation.hu.cv.apiResponse.successCvSave,
        status: SnackbarStatus.success,
      });
    } catch (err) {
      setSnackBarIsOpen({
        isOpen: true,
        message: Translation.hu.cv.apiResponse.failedCvSave,
        status: SnackbarStatus.error,
      });
    }
  };

  const getNotFormMessages = () => {
    const notCompletedFormMessages: string[] = [];
    if (shortSummary.trim().length < requiredCvItems.shortSummary) notCompletedFormMessages.push('Short summary');
    if (professionalBackgrounds.length < requiredCvItems.professionalBackground)
      notCompletedFormMessages.push('Professional background');
    if (competencies.length < requiredCvItems.competence) notCompletedFormMessages.push('Competencies');
    if (educations.length < requiredCvItems.education) notCompletedFormMessages.push('Educations');
    if (projects.length < requiredCvItems.projects) notCompletedFormMessages.push('Projects');
    if (languages.length < requiredCvItems.language) notCompletedFormMessages.push('Languages');
    return notCompletedFormMessages;
  };

  const isCompletedForm = () => {
    return (
      shortSummary.trim().length >= requiredCvItems.shortSummary &&
      professionalBackgrounds.length >= requiredCvItems.professionalBackground &&
      competencies.length >= requiredCvItems.competence &&
      educations.length >= requiredCvItems.education &&
      projects.length >= requiredCvItems.projects &&
      languages.length >= requiredCvItems.language
    );
  };

  const getSnackBarStatus = (): AlertColor | undefined => {
    return snackBarIsOpen.status === SnackbarStatus.default ? undefined : snackBarIsOpen.status;
  };

  const handleDownloadCv = () => {
    if (currentUser) {
      return `/api/cv-generator/generate-by-email/${currentUser.email}/pdf`;
    }
    return '';
  };

  useEffect(() => {
    if (currentUser) {
      CvGeneratorService.getCvData(currentUser.email)
        .then((response) => {
          const domainKds = response.domainKnowledges.map((domain, i) => {
            return {
              id: i,
              title: domain,
            };
          });

          setShortSummary(response.shortSummary);
          setProfessionalBackgrounds(response.backgrounds);
          setDevelopmentMtd(response.developmentMethodologies);
          setDomainKd(domainKds);
          setEducations(response.educations);
          setLanguages(response.languages);
          setProjects(response.projects);
          setCourses(response.courses);
          setCompetencies(response.competencies);
        })
        .catch(() => {
          setSnackBarIsOpen({
            isOpen: true,
            message: Translation.hu.cv.apiResponse.failedLoadCv,
            status: SnackbarStatus.error,
          });
        });
    }
  }, []);

  return (
    <div className="bs-cv-generator">
      <BsContentHeader></BsContentHeader>
      <CvGeneratorHeader />
      <CvGeneratorAccordionSummary
        shortSummary={shortSummary}
        updateSummary={updateSummary}
        maxCvItemsShortSummary={maxCvItems.shortSummary}
      />
      <CvGeneratorAccordionProfessionalBackground
        professionalBackgrounds={professionalBackgrounds}
        maxCvItemsProfessionalBackground={maxCvItems.professionalBackground}
        onAddNewProfessionalBg={onAddNewProfessionalBg}
        onEditProfessionalBg={onEditProfessionalBg}
        onRemoveProfessionalBg={onRemoveProfessionalBg}
        editedBg={editedBg}
      />
      <CvGeneratorAccordionDomain
        domainKd={domainKd}
        onUpdateDomainKnowledge={onUpdateDomainKnowledge}
        maxCvItemsDomainKnowledge={maxCvItems.domainKnowledge}
      />
      <CvGeneratorAccordionLanguage
        languages={languages}
        onAddNewlanguage={onAddNewlanguage}
        onRemoveLanguage={onRemoveLanguage}
      />
      <CvGeneratorAccordionDevelopmentMtd developmentMtd={developmentMtd} setDevelopmentMtd={updateDevelopmentMtd} />
      <CvGeneratorAccordionEducation
        educations={educations}
        editedEdu={editedEdu}
        onAddNewEducation={onAddNewEducation}
        onEditEducation={onEditEducation}
        onRemoveEducation={onRemoveEducation}
      />
      <CvGeneratorAccordionCompetence
        competencies={competencies}
        onAddNewCompetence={onAddNewCompetence}
        onRemoveCompetence={onRemoveCompetence}
        onUpdateCompetence={onUpdateCompetence}
        maxCvItemsCompetence={maxCvItems.competence}
      />
      <CvGeneratorAccordionCourse
        courses={courses}
        editedCourse={editedCourse}
        onEditCourse={onEditCourse}
        onRemoveCourse={onRemoveCourse}
        onAddNewCourse={onAddNewCourse}
        maxCvItemsCourse={maxCvItems.course}
      />
      <CvGeneratorAccordionProject
        projects={projects}
        editedProj={editedProj}
        onAddNewProject={onAddNewProject}
        onRemoveProject={onRemoveProject}
        onEditProject={onEditProject}
        setSnackbarIsOpen={setSnackBarIsOpen}
      />
      <br></br>
      <ConfirmationModal
        isCompletedForm={isCompletedForm()}
        notCompletedForm={getNotFormMessages()}
        open={modalIsOpen}
        onClose={handleOnClose}
      />
      <BsButton disabled={!hasChanges} onClick={() => setModalIsOpen(true)}>
        {Translation.hu.cv.form.save}
      </BsButton>
      <Link className="bs-cv-generator__download-btn" to={handleDownloadCv()} target="_blank" download>
        <BsButton color="secondary" size="normal" disabled={hasChanges}>
          {Translation.hu.cv.download}
        </BsButton>
      </Link>
      <Snackbar open={snackBarIsOpen.isOpen} autoHideDuration={6000} onClose={handleSnackBarClose}>
        <Alert onClose={handleSnackBarClose} severity={getSnackBarStatus()} sx={{ width: '100%' }}>
          {snackBarIsOpen.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CvGeneratorPage;
