import { ReactElement, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { BsRoutes } from '../../../data/bsRoutes';
import Translation from '../../../data/translation';
import BsCta from '../../ui/cta/cta';
import { useCurrentUserRights } from '../../../utils/useRight.hook';
import { AuthRight } from '@bb-sanctuary/common';
import { SendableBadges } from '../../custom/sendable-badges/SendableBadges';
import { BsGame } from '../../custom/game/game';

function BsSidebar(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const [hasRight] = useCurrentUserRights();
  const [isGameOn, setIsGameOn] = useState<boolean>(false);

  const navigateInnovation = () => {
    if (location.pathname !== BsRoutes.innovacio) {
      navigate(BsRoutes.innovacio);
    } else {
      const element = document.getElementById('bs-innovation__form');
      element && element.scrollIntoView();
    }
  };

  const navigateCV = () => {
    navigate(BsRoutes.cvGenerator);
  };

  const navigateToIncidentReport = () => {
    window
      .open(
        'https://docs.google.com/forms/d/e/1FAIpQLSdjJH2vb2CpEibZxuenePfYiVKYGIP3Ze7OxyYVE81p3IEygw/viewform',
        '_blank',
      )
      ?.focus();
  };

  const navigateCarreer = () => window.open('https://karrier.blackbelt.hu', '_blank');

  const onGameClose = () => {
    setIsGameOn(false);
  };

  const onGameOn = () => {
    setIsGameOn(!isGameOn);
  };

  return (
    <div className="bs-sidebar">
      <ul className="bs-sidebar__actions">
        <li className="bs-sidebar__actions__item">
          <BsCta onClick={navigateCarreer}>
            <span>{Translation.hu.cta.applyForAPosition}</span>
          </BsCta>
        </li>
        <li className="bs-sidebar__actions__item">
          <BsCta onClick={navigateToIncidentReport}>
            <span>{Translation.hu.cta.reportIncident}</span>
          </BsCta>
        </li>
        <li className="bs-sidebar__actions__item">
          <BsCta onClick={navigateInnovation}>
            <span>{Translation.hu.cta.innovate}</span>
          </BsCta>
        </li>
        {hasRight(AuthRight.CvCreate) && (
          <li className="bs-sidebar__actions__item">
            <BsCta onClick={navigateCV}>
              <span>{Translation.hu.cta.cv}</span>
            </BsCta>
          </li>
        )}
        <li className="bs-sidebar__actions__item">
          <BsCta onClick={onGameOn}>
            <span>{Translation.hu.cta.game}</span>
          </BsCta>
        </li>
        {isGameOn && <BsGame open={isGameOn} onClose={onGameClose} />}
      </ul>

      {location.pathname !== BsRoutes.profil._ && <SendableBadges />}
    </div>
  );
}

export default BsSidebar;
